import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Adblock from "src/components/adblock"
import Carbonate_disc_search from "src/components/carbonate_disc_search"
import Btf_carbonate from "src/components/btf_carbonate"

const Adblockdata = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    `}
    </style>
    <title>Block Website Ads - Carbonate</title></Helmet>
    <section>
      <Adblock data={Adblockdata}><Carbonate_disc_search></Carbonate_disc_search></Adblock>
      </section>
      <Btf_carbonate data={btfData}></Btf_carbonate>
    </HomepageLayout>
  )
}