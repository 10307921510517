import React from "react"
import Adblockdata from "./data/adblock.json"
import Styles from "./css/adblock.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Carbonate_disc_search from "src/components/carbonate_disc_search"

class Adblock extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: Adblockdata
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (typeof window != "undefined") {
        window.mapParams();
    }

    });
  }

  render() {
    return (
      <div className = {Styles.adBlock} style={{ display: this.state.display }}>
        <main>
        <header>
		        <img src={this.state.data.topLogo} alt="Carbonate Logo" />
		        <img src={this.state.data.checkMark} className = {Styles.check} alt="Compatibility Check" />
            <p>{this.state.data.headerText}</p>
      </header>

      <section>
			<h1 className="hc_param">{this.state.data.headline}</h1>
			<button className="cl1" data-cy="cta" onClick = {() => window.triggerInstall()}>
          <h2>{this.state.data.mainCTA}</h2>
          <span><img src={this.state.data.rightArrow} alt="Right Arrow" /></span>
      </button>
			<Carbonate_disc_search></Carbonate_disc_search>
			<ul>
              {this.state.data.bullets != undefined ? this.state.data.bullets.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
      </ul>
			<img className = {Styles.laptop} src={this.state.data.laptop} alt="no ads" />
      <h4>{this.state.data.headline2}</h4>
      <h5>{this.state.data.headline3}</h5>
		</section>
       </main>
            </div>
    )
  }
}
export default Adblock